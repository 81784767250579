<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-row class="mb-3">
        <b-col>
          <h4>Configuratore Prezzi</h4>
        </b-col>
        <b-col class="text-right">
          <h3 v-if="brandList">
            <b-badge variant="primary">{{ brandName }}</b-badge>
          </h3>
        </b-col>
      </b-row>
      <div v-if="file">
        <b-card class="mb-3">
          <b-row>
            <b-col>
              Ultimo file caricato:
              <b
                ><i>{{ file.filename }}</i></b
              >
            </b-col>
            <b-col>
              Data caricamento:
              <b
                ><i>{{ file.createDate }}</i></b
              >
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div>
        <b-card
          class="mb-3"
          v-for="(element, discountGroupIndex) in data.discountGroup"
          :key="discountGroupIndex"
        >
          <b-form-row>
            <b-col class="mb-3"
              >Gruppo sconto: <b>{{ element.discountId }}</b></b-col
            >
          </b-form-row>
          <b-form-row
            v-for="(field, fieldIndex) in element.field"
            :key="fieldIndex"
            class="mb-3"
          >
            <b-col cols="2"> {{ fieldName[field.get] }} =</b-col>
            <b-col cols="2">
              <b-form-select v-model="field.taking">
                <b-form-select-option
                  v-for="(taking, fieldTakingIndex) in fieldTaking"
                  :key="fieldTakingIndex"
                  :value="taking"
                  :disabled="isLoading"
                  >{{ fieldName[taking] }}
                </b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="1" class="text-right">
              <b-button
                @click="addPercentValue(discountGroupIndex, fieldIndex)"
                :disabled="isLoading"
              >
                <font-awesome-icon icon="plus" />
              </b-button>
            </b-col>
            <b-col
              v-for="(percent, percentIndex) in field.percent"
              :key="percentIndex"
              cols="1"
            >
              <b-input-group>
                <b-form-input
                  placeholder="%"
                  v-model="field.percent[percentIndex]"
                  :disabled="isLoading"
                  :state="percentFieldNotEmpty"
                />
                <b-input-group-append>
                  <b-button
                    @click="
                      removePercentValue(
                        discountGroupIndex,
                        fieldIndex,
                        percentIndex
                      )
                    "
                  >
                    <font-awesome-icon size="sm" icon="trash" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-form-row>
        </b-card>
      </div>
      <b-card class="mb-3">
        <b-form-row class="w-50">
          <label
            >Ricalcola listino prezzi con l'IVA italiana [Solo per fornitore
            intra-UE (%)]</label
          >
          <b-form-select
            v-model="data.vatAdaptorId"
            @change="setVatAdaptorValue"
            :disabled="isLoading"
          >
            <b-form-select-option
              v-for="vat in vatList"
              :value="vat.id"
              :key="vat.id"
              >{{ vat.name }}
              <span v-if="vat.value !== ''">[{{ vat.value }}%]</span>
            </b-form-select-option>
          </b-form-select>
          <input v-model="data.vatAdaptorValue" type="hidden" />
        </b-form-row>
      </b-card>
      <b-row>
        <b-col class="pb-3">
          <b-progress
            class="mb-3"
            v-if="previewIsLoading"
            :value="progressPreviewData"
            max="100"
            show-progress
            animated
          />
          <b-button
            @click="handleButton"
            :variant="isPreviewDataLoaded ? 'danger' : 'primary'"
            block
            size="lg"
            :disabled="previewIsLoading || setIsLoading"
            >{{ isPreviewDataLoaded ? "Salva" : "Preview" }}
            <b-spinner v-if="previewIsLoading || setIsLoading" small />
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="previewData.length > 0" class="pb-4">
        <b-col class="text-center">
          <div class="table-wrapper">
            <b-card title="Anteprima aggiornamento prezzi">
              <b-table
                hover
                striped
                :items="previewData"
                :fields="previewFields"
                sticky-header
              >
                <template #cell(web)="dataTable">
                  <font-awesome-icon
                    v-if="dataTable.item.webData"
                    icon="rss"
                    class="text-primary"
                    title="Web data"
                  />
                </template>
                <template #cell(update)="dataTable">
                  <font-awesome-icon
                    v-if="dataTable.item.error === ''"
                    icon="check-circle"
                    :class="
                      dataTable.item.insert === 1
                        ? 'text-primary'
                        : 'text-success'
                    "
                    :title="dataTable.item.insert === 1 ? 'Insert' : 'Update'"
                  />
                  <font-awesome-icon
                    v-else
                    icon="exclamation-triangle"
                    class="text-warning"
                    :title="dataTable.item.error"
                  />
                </template>
                <template #cell(barcode)="dataTable">
                  <b-link @click="setUrlBrowser(dataTable.item.barcode)"
                    >{{ dataTable.item.barcode }}
                  </b-link>
                </template>
                <template #cell(costPrice)="dataTable">
                  <span :id="'costPrice-' + dataTable.item.barcode">
                    {{ currencyFormatter(dataTable.item.costPrice) }}
                  </span>
                  <b-popover
                    :target="'costPrice-' + dataTable.item.barcode"
                    triggers="hover"
                    placement="left"
                  >
                    {{
                      currencyFormatter(
                        dataTable.item.calculation.priceList[
                          taking(dataTable.item.discountId).costPrice
                        ]
                      )
                    }}
                    <span
                      v-for="(field, index) in percent(
                        dataTable.item.discountId
                      ).costPrice"
                      :key="index"
                      >{{ parseInt(field) > 0 ? "+" + field : field }}%
                    </span>
                    =
                  </b-popover>
                </template>
                <template #cell(costPriceIncrease)="dataTable">
                  <span v-if="dataTable.item.insert === 0">
                    <span :id="'costPriceIncrease-' + dataTable.item.barcode">
                      <b
                        :class="{
                          'text-success': dataTable.item.costPriceIncrease < 0,
                          'text-danger': dataTable.item.costPriceIncrease > 0
                        }"
                        ><font-awesome-icon
                          v-if="dataTable.item.costPriceIncrease > 0"
                          icon="arrow-up"
                        /><font-awesome-icon
                          v-if="dataTable.item.costPriceIncrease < 0"
                          icon="arrow-down"
                        />
                        {{ dataTable.item.costPriceIncrease }} %</b
                      >
                    </span>
                    <b-popover
                      :target="'costPriceIncrease-' + dataTable.item.barcode"
                      triggers="hover"
                      placement="top"
                    >
                      <b
                        ><i>
                          {{
                            currencyFormatter(
                              dataTable.item.calculation.currentPrices.costPrice
                            )
                          }}</i
                        ></b
                      >
                    </b-popover>
                  </span>
                  <span v-else>
                    -
                  </span>
                </template>
                <template #cell(sellPrice)="dataTable">
                  <span :id="'sellPrice-' + dataTable.item.barcode">
                    {{ currencyFormatter(dataTable.item.sellPrice) }}
                  </span>
                  <b-popover
                    :target="'sellPrice-' + dataTable.item.barcode"
                    triggers="hover"
                    placement="left"
                  >
                    {{
                      currencyFormatter(
                        dataTable.item.calculation.priceList[
                          taking(dataTable.item.discountId).sellPrice
                        ]
                      )
                    }}
                    <span
                      v-for="(field, index) in percent(
                        dataTable.item.discountId
                      ).sellPrice"
                      :key="index"
                      >{{ parseInt(field) > 0 ? "+" + field : field }}%
                    </span>
                    =
                  </b-popover>
                </template>
                <template #cell(sellPriceIncrease)="dataTable">
                  <span v-if="dataTable.item.insert === 0">
                    <span :id="'sellPriceIncrease-' + dataTable.item.barcode">
                      <b
                        :class="{
                          'text-success': dataTable.item.sellPriceIncrease < 0,
                          'text-danger': dataTable.item.sellPriceIncrease > 0
                        }"
                        ><font-awesome-icon
                          v-if="dataTable.item.sellPriceIncrease > 0"
                          icon="arrow-up"
                        /><font-awesome-icon
                          v-if="dataTable.item.sellPriceIncrease < 0"
                          icon="arrow-down"
                        />
                        {{ dataTable.item.sellPriceIncrease }} %</b
                      >
                    </span>
                    <b-popover
                      :target="'sellPriceIncrease-' + dataTable.item.barcode"
                      triggers="hover"
                      placement="top"
                    >
                      <b
                        ><i>
                          {{
                            currencyFormatter(
                              dataTable.item.calculation.currentPrices.sellPrice
                            )
                          }}</i
                        ></b
                      >
                    </b-popover>
                  </span>
                  <span v-else>
                    -
                  </span>
                </template>
                <template #cell(listPrice)="dataTable">
                  <span :id="'listPrice-' + dataTable.item.barcode">
                    {{ currencyFormatter(dataTable.item.listPrice) }}
                  </span>
                  <b-popover
                    :target="'listPrice-' + dataTable.item.barcode"
                    triggers="hover"
                    placement="left"
                  >
                    <span v-if="data.vatAdaptorId !== ''">
                      {{
                        dataTable.item.calculation.priceList
                          .listPriceOriginalVat
                      }}
                      <b-badge
                        ><b
                          ><i>VAT {{ data.vatAdaptorId }} > IT</i></b
                        ></b-badge
                      >
                    </span>
                    {{
                      currencyFormatter(
                        dataTable.item.calculation.priceList[
                          taking(dataTable.item.discountId).listPrice
                        ]
                      )
                    }}
                    <span
                      v-for="(field, index) in percent(
                        dataTable.item.discountId
                      ).listPrice"
                      :key="index"
                      >{{ parseInt(field) > 0 ? "+" + field : field }}%
                    </span>
                    =
                  </b-popover>
                </template>
                <template #cell(listPriceIncrease)="dataTable">
                  <span v-if="dataTable.item.insert === 0">
                    <span :id="'listPriceIncrease-' + dataTable.item.barcode">
                      <b
                        :class="{
                          'text-success': dataTable.item.listPriceIncrease < 0,
                          'text-danger': dataTable.item.listPriceIncrease > 0
                        }"
                        ><font-awesome-icon
                          v-if="dataTable.item.listPriceIncrease > 0"
                          icon="arrow-up"
                        /><font-awesome-icon
                          v-if="dataTable.item.listPriceIncrease < 0"
                          icon="arrow-down"
                        />
                        {{ dataTable.item.listPriceIncrease }} %</b
                      >
                    </span>
                    <b-popover
                      :target="'listPriceIncrease-' + dataTable.item.barcode"
                      triggers="hover"
                      placement="top"
                    >
                      <b
                        ><i>
                          {{
                            currencyFormatter(
                              dataTable.item.calculation.currentPrices.listPrice
                            )
                          }}</i
                        ></b
                      >
                    </b-popover>
                  </span>
                  <span v-else>
                    -
                  </span>
                </template>
              </b-table>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <b-modal
        modal-class="modal-fullscreen"
        id="browser-modal"
        hide-footer
        size="xl"
      >
        <template #modal-title>
          <b-link @click="setLegacyAdminLogin">Login</b-link>
        </template>
        <div class="iframe-container" style="height: 100%">
          <iframe
            :src="urlBrowserModal"
            width="100%"
            height="100%"
            allowfullscreen
          ></iframe>
        </div>
      </b-modal>
      <b-modal id="save" hide-footer>
        <p class="my-4">
          Vuoi salvare questa configurazione per il produttore
          <b>{{ brandName }}</b
          >?
        </p>
        <b-row>
          <b-col class="text-right">
            <b-button
              variant="danger"
              @click="set"
              class="mr-3 w-25"
              :disabled="setIsLoading"
              >Salva
              <b-spinner v-if="setIsLoading" small />
            </b-button>
            <b-button
              variant="primary"
              @click="toggleModal('save')"
              class="w-25"
              >Annulla
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-container>
  </section>
</template>

<script>
// TODO ricerca in tabella
// filtra discount
// spinner
// configuratore web
// aggiungere totale articoli per ogni sezione di sconto
// ordine di gruppo sconto
// TODO manca virgola e euro su prezzo listino originale
// TODO togliere link barcode perchè non va o fixare
// TODO filtra per produttore
// TODO quando si modificano le configurazioni si chiede se importare nuovamente l'ultimo file uploadato (questo è utile in caso di correzioni sui margini dei prezzi)
//TODO Aggiungere mappature colonne nel caso non sia un artikelstamdaten standard (lo vede andando a leggere il file, se è caricato, altrimenti non propone mappatura dando per scontato che non serva)
//TODO Aggiungere flag 'nascondi articoli discontini (solo se non disponibili)'
//TODO Aggiungere inserisci articoli *** (categorie? immagini? traduzione testo?) Potrebbero essere inseriti non visibili, poi elencati a parte per linserimento delle immagini (che comunque potrebbero esssere caricate nella stessa interfaccia) e l'assegnazione della categoria in bulk
import { listManager } from "@/mixins/listManager";

const FIELD_NAME = {
  costPrice: "Prezzo di Costo",
  sellPrice: "Prezzo di Vendita",
  listPrice: "Prezzo di Listino"
};

export default {
  name: "ListManagerConfiguration",
  mixins: [listManager],
  data() {
    return {
      new: false,
      file: null,
      saved: null,
      data: {
        discountGroup: [],
        vatAdaptorId: "",
        vatAdaptorValue: ""
      },
      field: [
        {
          get: "costPrice",
          taking: "costPrice",
          percent: []
        },
        {
          get: "sellPrice",
          taking: "listPrice",
          percent: []
        },
        {
          get: "listPrice",
          taking: "listPrice",
          percent: []
        }
      ],
      fieldName: FIELD_NAME,
      brandId: this.$route.params.brandId,
      previewData: [],
      progressPreviewData: 0,
      setIsLoading: false,
      isPreviewDataLoaded: false,
      previewIsLoading: false,
      previewFields: [
        "update",
        "web",
        "barcode",
        "code",
        "costPrice",
        "costPriceIncrease",
        "sellPrice",
        "sellPriceIncrease",
        "listPrice",
        "listPriceIncrease",
        "discountId"
      ],
      urlBrowserModal: null,
      vatList: [
        {
          id: "",
          name: "Lascia invariato il listino prezzi con l'iva del fornitore",
          value: ""
        },
        { id: "DE", name: "German Vat", value: "19.00" }
      ],
      percentFieldNotEmpty: null,
      intervalId: null
    };
  },
  computed: {
    fieldTaking() {
      let result = [];
      if (
        this.data &&
        this.data.discountGroup[0] &&
        this.data.discountGroup[0].field
      )
        this.data.discountGroup[0].field.forEach(field => {
          result.push(field.taking);
        });
      return [...new Set(result)];
    },
    brandName() {
      return this.getBrandName(this.brandId);
    },
    isLoading() {
      return this.setIsLoading || this.previewIsLoading;
    }
  },
  watch: {
    file(value) {
      if (value && this.new) this.getDiscountGroup();
      if (value && !this.new) this.adapterDiscountGroup();
    },
    previewData() {
      this.previewIsLoading = false;
    },
    data: {
      handler() {
        this.previewData = [];
        this.isPreviewDataLoaded = false;
      },
      deep: true
    },
    saved(value) {
      console.log(value);
      if (value) this.$router.push({ name: "ListManager2" });
    },
    previewIsLoading(newValue) {
      if (newValue) this.startCheckProgressPreview();
      else this.stopCheckProgressPreview();
    }
  },
  mounted() {
    this.getBrand();
    this.get();
    this.getLastFile();
  },
  methods: {
    getLastFile() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_ENDPOINT}list-manager/last/${this.brandId}`
        )
        .then(response => {
          this.file = response.data;
        });
    },
    set: function() {
      this.setIsLoading = true;
      this.$http
        .post(
          `${process.env.VUE_APP_API_ENDPOINT}list-manager/configuration/${this.brandId}`,
          this.data
        )
        .then(res => {
          this.saved = !!res.data;
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.setIsLoading = false;
        });
    },
    preview: function() {
      this.percentFieldNotEmpty = this.checkEmptyPercentField();
      if (this.percentFieldNotEmpty) {
        this.previewIsLoading = true;
        this.$http
          .post(
            `${process.env.VUE_APP_API_ENDPOINT}list-manager/preview/${this.file.listManagerId}`,
            this.data
          )
          .then(res => {
            this.previewData = res.data;
            this.isPreviewDataLoaded = true;
          })
          .catch(err => {
            this.error = err;
          });
      }
    },
    progressPreview: function() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_ENDPOINT}list-manager/progress-preview/${this.file.listManagerId}/${this.file.total}`
        )
        .then(res => {
          this.progressPreviewData = res.data.progress;
        })
        .catch(err => {
          this.error = err;
        });
    },
    get: function() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_ENDPOINT}list-manager/configuration/${this.brandId}`
        )
        .then(res => {
          if (res.data) this.data = res.data;
          else this.new = true;
        })
        .catch(err => {
          this.error = err;
        });
    },
    addPercentValue: function(discountId, fieldId) {
      this.data.discountGroup[discountId].field[fieldId].percent.push("");
    },
    removePercentValue: function(discountId, fieldId, percentId) {
      this.data.discountGroup[discountId].field[fieldId].percent.splice(
        percentId,
        1
      );
    },
    getDiscountGroup: function() {
      let result = [];
      this.file.discountGroup.forEach(discountId => {
        const fieldsCopy = JSON.parse(JSON.stringify(this.field));
        this.data.discountGroup.push({
          discountId: discountId,
          field: fieldsCopy
        });
      });
      return result;
    },
    adapterDiscountGroup() {
      if (this.data.discountGroup.length > this.file.discountGroup.length)
        this.data.discountGroup = this.data.discountGroup.slice(
          0,
          this.file.discountGroup.length
        );
      if (this.data.discountGroup.length < this.file.discountGroup.length) {
        let newElement =
          this.file.discountGroup.length - this.data.discountGroup.length;
        let array = [...Array(newElement).keys()];
        array.forEach(discountId => {
          this.data.discountGroup.push({
            discountId: parseInt(discountId + newElement + 1).toString(),
            field: this.field
          });
        });
      }
    },
    percent(discountId) {
      let result = [];
      if (this.data.discountGroup[discountId])
        this.data.discountGroup[discountId].field.forEach(field => {
          result[field.get] = field.percent.filter(function(element) {
            return element !== "";
          });
        });
      return result;
    },
    taking(discountId) {
      let result = [];
      let discountGroup = this.data.discountGroup.find(
        el => el.discountId === discountId
      );
      if (discountGroup)
        discountGroup.field.forEach(field => {
          result[field.get] = field.taking;
        });
      return result;
    },
    currencyFormatter(number) {
      let currencyFormatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR"
      });
      return currencyFormatter.format(number);
    },
    setLegacyAdminLogin() {
      this.urlBrowserModal = "https://admin.tecnomodel-treni.it/";
    },
    setUrlBrowser(barcode) {
      this.urlBrowserModal =
        "https://cls.tecnomodel-treni.it/items.php?search=" + barcode;
      this.showModal("browser-modal");
    },
    showModal(target) {
      this.$root.$emit("bv::show::modal", target);
    },
    toggleModal(target) {
      this.$root.$emit("bv::toggle::modal", target, "#btnToggle");
    },
    handleButton() {
      return this.isPreviewDataLoaded ? this.showModal("save") : this.preview();
    },
    setVatAdaptorValue(id) {
      this.data.vatAdaptorValue = this.vatList.find(el => el.id === id).value;
    },
    checkEmptyPercentField() {
      let result = true;
      this.data.discountGroup.forEach(discount => {
        discount.field.forEach(fieldItem => {
          const isValid = fieldItem.percent.every(
            val => typeof val === "string" && val.trim().length > 0
          );
          if (!isValid) {
            result = false;
          }
        });
      });
      return result;
    },
    startCheckProgressPreview() {
      if (this.previewIsLoading)
        this.intervalId = setInterval(() => {
          this.progressPreview();
        }, 1500);
    },
    stopCheckProgressPreview() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }
  },
  beforeDestroy() {
    this.stopCheckProgressPreview();
  }
};
</script>

<style>
.table-wrapper {
  max-height: 500px;
  overflow-y: auto;
}

.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 50px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100vh - 50px);
  display: flex;
  position: fixed;
  z-index: 100000;
}

.form-control.is-invalid {
  background-image: none !important;
}

.form-control.is-valid {
  background-image: none !important;
}
</style>
